var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-architecture-and-city"}),_c('span',{staticClass:"menu-text"},[_vm._v("Dashboard")])])])]}}])}),(
      _vm.permission.some(
        (item) =>
          item.name === 'can_view_presence_report' ||
          item.name === 'can_view_daily_report' ||
          item.name === 'can_view_incident_report' ||
          item.name === 'can_view_patrol_report'
      )
    )?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("General")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),(
      _vm.permission.some(
        (item) =>
          item.name === 'can_view_presence_report' ||
          item.name === 'can_view_daily_report' ||
          item.name === 'can_view_incident_report' ||
          item.name === 'can_view_patrol_report'
      )
    )?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-open': _vm.hasActiveChildren('/report'),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(0),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/report/absensi"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [(
              _vm.permission.some(
                (item) => item.name === 'can_view_presence_report'
              )
            )?_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Absensi")])])]):_vm._e()]}}],null,false,2362601792)}),_c('router-link',{attrs:{"to":"/report/harian"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [(
              _vm.permission.some((item) => item.name === 'can_view_daily_report')
            )?_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Harian")])])]):_vm._e()]}}],null,false,2744144316)}),_c('router-link',{attrs:{"to":"/report/kejadian"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [(
              _vm.permission.some(
                (item) => item.name === 'can_view_incident_report'
              )
            )?_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Kejadian")])])]):_vm._e()]}}],null,false,1934940201)}),_c('router-link',{attrs:{"to":"/report/patrol"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [(
              _vm.permission.some(
                (item) => item.name === 'can_view_patrol_report'
              )
            )?_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Patroli")])])]):_vm._e()]}}],null,false,1991610161)})],1)])]):_vm._e(),(
      _vm.permission.some(
        (item) =>
          item.name === 'can_view_list_customer_branch' ||
          item.name === 'can_view_list_customer'
      )
    )?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Manage")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),(_vm.permission.some((item) => item.name === 'can_view_list_employee'))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-open': _vm.hasActiveChildren('/employee'),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(1),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/employee/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Employee")])])])]}}],null,false,1219504115)}),_c('router-link',{attrs:{"to":"/employee/import"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Import Employee")])])])]}}],null,false,2096589070)})],1)])]):_vm._e(),(
      _vm.permission.some(
        (item) =>
          item.name === 'can_view_list_customer_branch' ||
          item.name === 'can_view_list_customer'
      )
    )?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-open': _vm.hasActiveChildren('/customer'),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(2),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/customer/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [(
              _vm.permission.some(
                (item) => item.name === 'can_view_list_customer'
              )
            )?_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Site Group")])])]):_vm._e()]}}],null,false,819503044)}),_c('router-link',{attrs:{"to":"/customer/branch"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [(
              _vm.permission.some(
                (item) => item.name === 'can_view_list_customer_branch'
              )
            )?_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Site")])])]):_vm._e()]}}],null,false,3742707504)})],1)])]):_vm._e(),(
      _vm.permission.some((item) => item.name === 'can_submit_broadcast_message')
    )?_c('router-link',{attrs:{"to":"/brodcast"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-speaker"}),_c('span',{staticClass:"menu-text"},[_vm._v("Brodcast Message")])])])]}}],null,false,4268653224)}):_vm._e(),(_vm.permission.some((item) => item.name === 'can_view_list_admin'))?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Admin")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),(_vm.permission.some((item) => item.name === 'can_view_list_admin'))?_c('router-link',{attrs:{"to":"/admin"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-user-add"}),_c('span',{staticClass:"menu-text"},[_vm._v("Admin")])])])]}}],null,false,3090438790)}):_vm._e(),(_vm.permission.some((item) => item.name === 'can_view_list_role'))?_c('router-link',{attrs:{"to":"/role"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-user-add"}),_c('span',{staticClass:"menu-text"},[_vm._v("Role")])])])]}}],null,false,3589398173)}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon2-graph"}),_c('span',{staticClass:"menu-text"},[_vm._v("Report")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon2-group"}),_c('span',{staticClass:"menu-text"},[_vm._v("Karyawan")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon2-soft-icons-1"}),_c('span',{staticClass:"menu-text"},[_vm._v("Site Group")]),_c('i',{staticClass:"menu-arrow"})])
}]

export { render, staticRenderFns }