<template>
  <ul class="menu-nav">
    <router-link
      to="/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">Dashboard</span>
        </a>
      </li>
    </router-link>

    <!-- <router-link
      to="/builder"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-expand"></i>
          <span class="menu-text">Layout Builder</span>
        </a>
      </li>
    </router-link> -->

    <li
      class="menu-section"
      v-if="
        permission.some(
          (item) =>
            item.name === 'can_view_presence_report' ||
            item.name === 'can_view_daily_report' ||
            item.name === 'can_view_incident_report' ||
            item.name === 'can_view_patrol_report'
        )
      "
    >
      <h4 class="menu-text">General</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/report'),
      }"
      v-if="
        permission.some(
          (item) =>
            item.name === 'can_view_presence_report' ||
            item.name === 'can_view_daily_report' ||
            item.name === 'can_view_incident_report' ||
            item.name === 'can_view_patrol_report'
        )
      "
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-graph"></i>
        <span class="menu-text">Report</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            to="/report/absensi"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
              v-if="
                permission.some(
                  (item) => item.name === 'can_view_presence_report'
                )
              "
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Absensi</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/report/harian"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
              v-if="
                permission.some((item) => item.name === 'can_view_daily_report')
              "
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Harian</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/report/kejadian"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
              v-if="
                permission.some(
                  (item) => item.name === 'can_view_incident_report'
                )
              "
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Kejadian</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/report/patrol"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
              v-if="
                permission.some(
                  (item) => item.name === 'can_view_patrol_report'
                )
              "
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Patroli</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      class="menu-section"
      v-if="
        permission.some(
          (item) =>
            item.name === 'can_view_list_customer_branch' ||
            item.name === 'can_view_list_customer'
        )
      "
    >
      <h4 class="menu-text">Manage</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/employee'),
      }"
      v-if="permission.some((item) => item.name === 'can_view_list_employee')"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-group"></i>
        <span class="menu-text">Karyawan</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            to="/employee/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Employee</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/employee/import"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Import Employee</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/customer'),
      }"
      v-if="
        permission.some(
          (item) =>
            item.name === 'can_view_list_customer_branch' ||
            item.name === 'can_view_list_customer'
        )
      "
    >
      <!-- <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/customer'),
      }"
    > -->
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-soft-icons-1"></i>
        <span class="menu-text">Site Group</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            to="/customer/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
              v-if="
                permission.some(
                  (item) => item.name === 'can_view_list_customer'
                )
              "
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Site Group</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/customer/branch"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
              v-if="
                permission.some(
                  (item) => item.name === 'can_view_list_customer_branch'
                )
              "
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Site</span>
              </a>
            </li>
          </router-link>
          <!-- <router-link
            to="/customer/qr-code"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Generate QR Code</span>
              </a>
            </li>
          </router-link> -->
        </ul>
      </div>
    </li>
    <router-link
      to="/brodcast"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="
        permission.some((item) => item.name === 'can_submit_broadcast_message')
      "
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-speaker"></i>
          <span class="menu-text">Brodcast Message</span>
        </a>
      </li>
    </router-link>
    <li
      class="menu-section"
      v-if="permission.some((item) => item.name === 'can_view_list_admin')"
    >
      <h4 class="menu-text">Admin</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>
    <!-- <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/master'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon-folder-1"></i>
        <span class="menu-text">Master</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            to="/master/service"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Service</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/master/bank"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Bank</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/master/lvl-jabatan"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Level Jabatan</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/master/jabatan"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Jabatan</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/master/divisi"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Divisi</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/master/inisiasi"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Inisiasi</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/master/cabang"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Cabang</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/master/site-group"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Site Group</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li> -->
    <!-- <router-link
      to="/user"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-user-add"></i>
          <span class="menu-text">User</span>
        </a>
      </li>
    </router-link> -->
    <router-link
      to="/admin"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="permission.some((item) => item.name === 'can_view_list_admin')"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-user-add"></i>
          <span class="menu-text">Admin</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/role"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="permission.some((item) => item.name === 'can_view_list_role')"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-user-add"></i>
          <span class="menu-text">Role</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "KTMenu",
  data() {
    return {
      listMenu: [
        {
          name: "Dasboard",
          route: "/dashboard",
          icon: "menu-icon flaticon2-architecture-and-city",
          children: [],
        },
        {
          name: "Layout Builder",
          route: "/builder",
          icon: "menu-icon flaticon2-expand",
          children: [],
        },
        {
          name: "Report",
          route: "",
          icon: "menu-icon flaticon2-graph",
          children: [
            {
              name: "Absensi",
              route: "/report/absensi",
              icon: "menu-bullet menu-bullet-dot",
            },
            {
              name: "Harian",
              route: "/report/harian",
              icon: "menu-bullet menu-bullet-dot",
            },
            {
              name: "Kejadian",
              route: "/report/kejadian",
              icon: "menu-bullet menu-bullet-dot",
            },
            {
              name: "Patrol",
              route: "/report/patroli",
              icon: "menu-bullet menu-bullet-dot",
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["permission"]),
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
};
</script>
